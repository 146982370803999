import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'date-dropdown',
  templateUrl: './date-dropdown.component.html',
  styleUrls: ['./date-dropdown.component.css']
})
export class DateDropdownComponent implements OnInit{

  @Output() change = new EventEmitter<any>();
  @Input() filter: boolean = false;
  @Input() dateFilter: boolean = false;
  filterPopup: boolean = false;
  fromDate!: Date | null;
  toDate!: Date | null;
  prevFilterType: string = 'Custom';
  filterType: string = 'Custom';
  @Input() inputDates: string[] = []; 
  startDate!: Date;
  endDate!: Date;

  constructor() {
    
  }

  ngOnInit(): void {
    let currentDate = new Date()
    this.endDate = currentDate;
    this.startDate = new Date(currentDate.getTime() - 90*24*60*60*1000);
    console.log(this.inputDates)
    if(this.inputDates?.length > 1) {
      let today = new Date((new Date()).toISOString().split('T')[0]);
      let fromDate = (new Date(this.inputDates[0]));
      let toDate = (new Date(this.inputDates[1]));
      console.log(today);
      console.log(fromDate);
      console.log(toDate);
      if(this.matchDates(toDate,today) && this.matchDates(fromDate,new Date(today.getTime() - 24*60*60*1000))) {
        this.setDates('Today'); 
        this.prevFilterType = 'Today';
      }
      else if(this.matchDates(toDate,new Date(today.getTime() - 24*60*60*1000)) && this.matchDates(fromDate,new Date(today.getTime() - 2*24*60*60*1000))) {
        this.setDates('Yesterday'); this.prevFilterType = 'Yesterday';
      }
      else if(this.matchDates(fromDate, new Date(today.getTime() - 7*24*60*60*1000))) {
        this.setDates('Last 7 days');
        this.prevFilterType = 'Last 7 days';
      }
      else if(this.matchDates(fromDate, new Date(today.getTime() - 30*24*60*60*1000))) {
        this.setDates('Last 30 days');
        this.prevFilterType = 'Last 30 days';
      }
      else if(this.matchDates(fromDate, new Date(today.getTime() - 90*24*60*60*1000))) {
        this.setDates('Last 90 days');
        this.prevFilterType = 'Last 90 days';
      }
      else if(this.matchDates(fromDate, new Date(today.getTime() - 365*24*60*60*1000))) {
        this.setDates('Last 365 days');
        this.prevFilterType = 'Last 365 days';
      }
      else {
        this.fromDate = new Date(this.inputDates[0]);
        this.toDate = new Date(this.inputDates[1]);
      }
    } else this.setDates(this.filterType);
  }

  matchDates(date1: any, date2: any) {
    let d1 = new Date(date1).toISOString().split('T')[0];
    let d2 = new Date(date2).toISOString().split('T')[0];
    if(d1 === d2) return true;
    else return false
  }

  setDates(type: string) {
    let today = new Date()
    if(type === 'Today'){
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 24*60*60*1000);
    } else if(type === 'Yesterday') {
      this.filterType = type;
      this.toDate = new Date(today.getTime() - 24*60*60*1000);
      this.fromDate = new Date(today.getTime() - 2*24*60*60*1000);
    } else if(type === 'Last 7 days') {
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 7*24*60*60*1000);
    } else if(type === 'Last 30 days') {
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 30*24*60*60*1000);
    } else if(type === 'Last 90 days') {
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 90*24*60*60*1000);
    } else if(type === 'Last year') {
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 365*24*60*60*1000);
    } else if(type === 'Custom') {
      this.filterType = type;
      this.toDate = null;
      this.fromDate = null;
    }
  }

  cancelFilter() {
    this.filterPopup = false; 
    this.filterType = this.prevFilterType; 
    this.setDates(this.filterType);
  }

  applyTimeframe() {
    this.prevFilterType = this.filterType;
    if(this.fromDate && this.toDate){
      let fromString = new Date(this.fromDate.getTime() - (this.fromDate.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      let toString = new Date(this.toDate.getTime() - (this.toDate.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      this.change.emit([fromString,toString]);
    } else this.change.emit(['','']);
    this.filterPopup = false;
  }
}
